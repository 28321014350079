import { useState } from 'react'
import CountDownExpired from 'src/components/common/Countdown/CountdownExpired'
import { useInView } from 'react-intersection-observer'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { getFeatureObject } from 'src/utils/amplitude/getFeatureObject'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'

import { Countdown } from '../../common/Countdown/Countdown'

type CountdownProps = {
  enableSession?: boolean
  endDateOfCountdown?: string
  linkUrl: string
  section?: string
  locationOnPage?: string
  title?: string
  description?: string
  buttonText?: string
  background?: string
  expiredTitle?: string
  expiredDescription?: string
}

export const BlackFridayCountdown = (props: CountdownProps) => {
  const {
    enableSession,
    endDateOfCountdown,
    linkUrl,
    locationOnPage,
    title,
    description,
    expiredTitle,
    expiredDescription,
    buttonText,
    background,
    section,
  } = props

  const [isCountdownExpired, setIsCountdownExpired] = useState<
    boolean | undefined
  >(false)

  const { ref } = useInView({
    threshold: 0.7,
    triggerOnce: true,
    onChange: (inView) => {
      if (!inView) {
        return
      }

      sendPromoFeatureViewedEvent()
    },
  })

  function sendPromoFeatureViewedEvent() {
    if (!locationOnPage || !section) {
      return
    }

    const eventData = {
      ...getStandardObject(),
      ...getFeatureObject({
        section,
        locationOnPage,
        name: 'VEM AÍ BLACK FRIDAY DECATHLON',
      }),
    }

    dispatchAmplitudeEvent({ eventName: 'Promo Feature Viewed', eventData })
  }

  if (!enableSession || !endDateOfCountdown) {
    return null
  }

  return isCountdownExpired ? (
    <CountDownExpired
      section={section}
      locationOnPage={locationOnPage}
      link={linkUrl}
      buttonText={buttonText}
      expiredTitle={expiredTitle}
      expiredDescription={expiredDescription}
    />
  ) : (
    <div
      ref={ref}
      className="flex flex-col gap-3 p-4 items-center justify-between restructure-small-desktop:flex-row restructure-small-desktop:justify-center restructure-small-desktop:gap-10 restructure-small-desktop:py-5"
      style={{
        background,
      }}
    >
      <div className="text-white flex flex-col gap-0.5">
        <h2 className="uppercase text-xs leading-[18px] font-black tracking-[0.24px] restructure-small-desktop:tracking-[0.16px] restructure-small-desktop:text-base restructure-small-desktop:leading-[24px]">
          {title}
        </h2>
        <p className="text-[10px] leading-[15px] font-medium restructure-small-desktop:text-[14px] restructure-small-desktop:leading-[21px] restructure-small-desktop:tracking-[-0.154px]">
          {description}
        </p>
      </div>
      {!isCountdownExpired && (
        <Countdown
          countdownId="mainCountdown"
          parsedEndDateOfCountdown={endDateOfCountdown}
          setIsCountdownExpired={setIsCountdownExpired}
        />
      )}
    </div>
  )
}
